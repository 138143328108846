const { REACT_APP_TOKEN } = process.env;

export const API_URL = 'https://zancanaro.duocyber.com';
export const axiosconfig = {
    headers: { Authorization: REACT_APP_TOKEN }
};

export const axiosconfigwithfiles = {
    headers: { Authorization: REACT_APP_TOKEN, 'Content-Type': 'multipart/form-data' }
};
export const GOOGLE_SITE_KEY = '6LfF5pYaAAAAAAmCrFH-96GkxesgVMt8SOj4Xyj5';

