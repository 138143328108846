import Banner from "../../components/banner/Banner";
import { Bannerorcamento } from "../../components/bannerorcamento/Bannerorcamento";
import BlogHome from "../../components/bloghome/Bloghome";
import Contato from "../../components/contato/Contato";
import Header from "../../components/header/Header";
import Servicos from "../../components/servicos/Servicos";
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import imgAlerta from '../../images/avisoboleto.png';

export function Home() {
    const [displayPopUp, setDisplayPopUp] = useState(true);

    const closePopUp = () => {
        // setting key "seenPopUp" with value true into localStorage
        localStorage.setItem("seenPopUp", 'true');
        setDisplayPopUp(false);
    };

    useEffect(() => {
        // getting value of "seenPopUp" key from localStorage
        let returningUser = localStorage.getItem("seenPopUp");
        if (returningUser === 'true') {
            setDisplayPopUp(false);
        }
    }, []);
    return (
        <div className="App">
            {
                <div>
                <Modal show={displayPopUp} size="sm">
                    <Modal.Body >
                        <a href="https://grupozancanaro.eng.br/pub/fraude-de-boletos" onClick={closePopUp}>
                        <img src={imgAlerta} alt="garantimos" className="w-80"/></a>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closePopUp} style={{ backgroundColor: '#ff6000', border: 'none' }}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                </div>
            }
            <div className="clearfix">
                <Header />
                <Banner />
                <Servicos />
                <Bannerorcamento />
                <BlogHome />
                <Contato />
            </div>
        </div>

    );
}